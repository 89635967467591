<template>
  <div>
    <NuxtLoadingIndicator color="#7547ff" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <div v-if="authenticated && user && !user.organization.name.toLowerCase().includes('wobby')">
      <ClientOnly>
        <LiveChatWidget license="18351804" visibility="minimized" :customer-name="user?.name"
          :customer-email="user?.email" :session-variables="{
            userId: user?.id,
            organization: `${user?.organization.name} - ${user?.organization.tier.name}`,
            country: user?.country,
            trialEndsAt: user?.trialEndsAt
          }" />
      </ClientOnly>
    </div>
    <ClientOnly>
      <!-- Fix Hydration errors -->
      <DialogsOrganizationLimitExceededDialog />
      <UNotifications />
      <SignoutDialog />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { watch, onMounted, ref } from 'vue'
import SignoutDialog from './components/dialogs/SignoutDialog.vue';
import { LiveChatWidget } from '@livechat/widget-vue'

const identityStore = useIdentityStore();
const { user, authenticated } = storeToRefs(identityStore);
const { gtag } = useGtag();

onMounted(() => {
  watch(user, (newUser) => {
    if (newUser) gtag('set', { user_id: newUser.id, email: newUser.email });
  }, { immediate: true });
});


const mixpanel = useMixpanel();
onMounted(() => {
  if (mixpanel) {
    if (user.value) {
      mixpanel.identify(user.value.email);
      mixpanel.setUserProperties({
        email: user.value.email,
        name: user.value.name,
        organization: `${user.value.organization.name}`,
        country: user.value.country,
        trialEndsAt: user.value.trialEndsAt ?? '',
        createdAt: user.value.createdAt,
      })
    }
  }
});

</script>