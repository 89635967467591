<template>
  <UModal :ui="{
    wrapper: 'z-[9999]'
  }" v-model="appStore.dialogs.signout">
    <UCard>
      <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button type="button"
          class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          @click="appStore.toggleDialog('signout', false)">
          <span class="sr-only">Close</span>
          <Icon name="heroicons:x-mark-20-solid" class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <Icon name="heroicons:exclamation-triangle" class="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <div class="mt-2">
            <p class="text-sm text-gray-500">Are you sure you want to sign out?</p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button type="button"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          @click="submit">Sign out</button>
        <button type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          @click="appStore.toggleDialog('signout', false)">Cancel</button>
      </div>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { ref } from 'vue';
import { useIdentityService } from '@/composables/useIdentityService';

const appStore = useAppStore();
const identityService = useIdentityService();

const loading = ref(false)

const submit = async () => {
  try {
    loading.value = true;
    await identityService.signout();
    useTrackEvent('logout')
    location.reload();
  } catch (error) {
    alert(error)
  } finally {
    loading.value = false;
  }
}

</script>