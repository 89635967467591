import { useIdentityStore } from '@/stores/identity';
import mixpanel from 'mixpanel-browser';

export const useMixpanel = () => {
    if (!process.client) return { track: () => { }, identify: () => { }, setUserProperties: () => { }, increment: () => { } };
    const identityStore = useIdentityStore();

    try {
        mixpanel.init('0b2278d254c751a40c72a4d369e19efc', {
            track_pageview: "url-with-path-and-query-string",
            persistence: 'localStorage'
        });
    } catch (error) {
        console.error('Error initializing Mixpanel:', error);
    }

    function track(event: string, properties?: Record<string, any>) {
        try {
            mixpanel.track(event, {
                ...properties,
                email: identityStore.user?.email,
                service: 'frontend-nuxt'
            });
        } catch (error) {
            console.error('Error tracking event:', error);
        }
    }

    function identify(email: string) {
        try {
            mixpanel.identify(email);
        } catch (error) {
            console.error('Error identifying user:', error);
        }
    }

    function increment(event: string, value: number) {
        try {
            mixpanel.people.increment(event, value);
        } catch (error) {
            console.error('Error incrementing event:', error);
        }
    }

    function setUserProperties(properties: Record<string, string | undefined>) {
        try {
            mixpanel.people.set({
                $email: properties.email,
                $name: properties.name,
                created: properties.createdAt,
                organization: properties.organization,
                country: properties.country
            });
        } catch (error) {
            console.error('Error setting user properties:', error);
        }
    }

    return {
        track,
        identify,
        setUserProperties,
        increment
    };
};