import { useIdentityService } from '@/composables/useIdentityService'
import type { User, UserPreference } from '@/types'

import { defineStore } from 'pinia'

export const useIdentityStore = defineStore('identity', {
  state: () => {
    return {
      authenticationLoading: true,
      authenticated: false,
      jwtFetched: false,
      user: null as User | null,
      preferences: null as UserPreference | null,
    }
  },
  actions: {
    async initIdentity(): Promise<boolean> {
      const identityService = useIdentityService();
      const status = await identityService.getAuthenticationStatus();

      if (status.authenticated) {
        this.user = status.user;
        this.authenticated = true;
        this.preferences = await identityService.getUserPreferences(status.user!.id);
      }

      this.authenticationLoading = false;
      return status.authenticated;
    },
    async ensureJwtFetched() {
      if (this.jwtFetched) return;
      const identityService = useIdentityService();
      await identityService.getAuthenticationStatus();
      this.jwtFetched = true;
    },
    async login(email: string, password: string): Promise<void> {
      const identityService = useIdentityService();
      await identityService.loginWithLocalAccount({ email, password, keepSignedIn: true });
      await this.initIdentity();
    },
    async updateUser(user: Partial<User>) {
      const identityService = useIdentityService();
      const updated = await identityService.updateUser(user);
      this.user = updated;
    }
  },
  getters: {
    hasFeature: (state) => (feature: string) => {
      if (!state.user) return false;
      if (!state.user.organization) return false;
      return state.user.organization.tier.features.map(e => e.name).includes(feature) || false;
    },
    isUserInActiveTrial: (state) => {
      if (!state.user) {
        return false;
      }
    
      const now = new Date().getTime();
      const trialEndTime = new Date(state.user.trialEndsAt!).getTime();
      const isTrialTier = state.user.organization?.tier.name === 'TRIAL';
      const isTrialActive = trialEndTime >= now;
    
      return isTrialTier && isTrialActive;
    }
  }
})
