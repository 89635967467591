import { useIdentityStore } from "@/stores/identity";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const identityStore = useIdentityStore()
    const { isUserInActiveTrial, user } = storeToRefs(identityStore)

    // check if user is already in 'trial-expired' page
    if (to.path === '/trial-expired') return;

    if (!user.value?.trialEndsAt) return;
    if (isUserInActiveTrial.value) return;

    try {
        const trialIsInPast = new Date(user.value?.trialEndsAt!) < new Date();
        const tierIsTrial = user.value?.organization.tier.name === 'TRIAL';
        const trialIsExpired = trialIsInPast && tierIsTrial;

        if (trialIsExpired) {
            return navigateTo('/trial-expired');
        }
    } catch (e) {
        console.log('Error checking trial status: ', e);
        return;
    }
})