import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45maintenance_45global from "/app/middleware/01.maintenance.global.ts";
import _02_45auth_45global from "/app/middleware/02.auth.global.ts";
import _03_45trial_45global from "/app/middleware/03.trial.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45maintenance_45global,
  _02_45auth_45global,
  _03_45trial_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}