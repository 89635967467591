import { defineStore } from 'pinia'

interface BreadcrumbItem {
  text: string;
  href: string;
  icon: string;
}

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      sidebar: false,
      breadcrumbs: [] as BreadcrumbItem[],
      dialogs: {
        signout: false,
        createDataset: false,
        createWorkspace: false,
        settings: false,
        upgrade: false,
        onboarding: false,
      } as Record<string, boolean>,
      dialogExtraMessage: '',
      notification: {
        type: "",
        title: "",
        message: "",
        duration: 0,
        trigger: false,
        closeable: true,
      },
      banner: {
        show: false,
        message: '',
        link: null,
        linkText: '',
      }
    }
  },
  actions: {
    triggerNotification(type: string, title: string, message: string, duration: number, closeable: boolean) {
      this.notification = { type, title, message, duration, trigger: true, closeable }
    },
    toggleDialog(dialog: string, value: boolean, message?: string) {
      this.dialogs[dialog] = value;
      this.dialogExtraMessage = message || '';
    },
    setBreadcrumbs(list: BreadcrumbItem[]) {
      this.breadcrumbs = list;
    },
    setSidebar(show: boolean) {
      this.sidebar = !this.sidebar;
    },
  },
})
