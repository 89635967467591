import { useIdentityStore } from "@/stores/identity";



export default defineNuxtRouteMiddleware(async (to, from) => {
    const identityStore = useIdentityStore();
    const { authenticated, user, jwtFetched } = storeToRefs(identityStore)

    if (process.server) {
        await identityStore.initIdentity()
    }

    if (process.client && !jwtFetched.value) {
        await identityStore.initIdentity();
        jwtFetched.value = true;
    }

    const whitelistRegex = [
        /^\/auth\/login$/,
        /^\/auth\/register$/,
        /^\/auth\/reset-password$/,
        /^\/workspaces\/shared\/[a-zA-Z]{6}$/,
        /^\/workspaces\/chat\/[a-zA-Z]{6}$/,
        /^\/documents\/[a-zA-Z0-9-]+$/,
    ];
    

 

    if (!authenticated.value) {
        if (!whitelistRegex.some(regex => regex.test(to.path))) return navigateTo('/auth/login');
    } else if (!user.value?.onboarded && !to.fullPath.includes('/auth/onboarding')) {
        return navigateTo('/auth/onboarding');
    } else if (to.fullPath.includes('/auth/onboarding') && user.value?.onboarded) {
        return navigateTo('/');
    } else if (to.fullPath.includes('/auth/login') || to.fullPath.includes('/auth/register')) {
        return navigateTo('/');
    }
})