import { AppConfigurationClient } from '@azure/app-configuration'


export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.client) return

    if (to.fullPath === '/maintenance') return

    try {
        const config = useRuntimeConfig()
        const client = new AppConfigurationClient(config.appConfigEndpoint);

        try {
            const response = await client.getConfigurationSetting({
                key: '.appconfig.featureflag/maintenance-mode'
            });

            if (response.value) {
                let json = JSON.parse(response.value!)

                if (json.enabled) {
                    console.log('Maintenance mode is enabled')
                    return navigateTo('/maintenance');
                }
            }
        } catch (error) {
            console.error(error)
        }

        try {
            const appStore = useAppStore();
            const response = await client.getConfigurationSetting({
                key: 'app-banner'
            });

            if (response.value) {
                appStore.banner = JSON.parse(response.value);
            }

        } catch (error) {
            console.error(error)
        }
    } catch (error) {
        console.error(error)
    }
})